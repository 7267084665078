// Override default variables before the import
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

$white: #ffffff;
$black: #000000;
$green: #b2ff0f;
$lightblue: #00f0ff;
$midblue: #00cfdc;
$blue: #158be0;
$darkblue: #055598;

@import url("https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&display=swap");

body {
  font-family: "Afacad Flux", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  overflow: hidden;
  overflow-y: scroll;
}

h1 {
  font-family: "Afacad Flux", sans-serif;
  font-weight: 700;
  font-size: 70px;
  line-height: 63px;
  @include media-breakpoint-down(md) {
    font-size: 46px;
  }
}

h2 {
  font-size: 57px;
  line-height: 63px;
  font-weight: 800;
  @include media-breakpoint-down(md) {
    font-size: 46px;
  }
}

h3 {
  font-family: "Afacad Flux", sans-serif;
  font-size: 37px;
  line-height: 39px;
  font-weight: 600;
}

h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 135%;
}

h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
}

img {
  width: 100%;
  height: auto;
}

.mobile {
  @include media-breakpoint-down(xl) {
    display: none;
  }

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.desktop {
  @include media-breakpoint-down(xl) {
    display: block;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

/******************************
/
/     Header Module
/
******************************/

.header {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background: linear-gradient(
    180deg,
    #055598 5.53%,
    rgba(19, 129, 221, 0) 75.85%
  );
  .wrapper {
    max-width: 1280px;
    margin: 0 auto;
    padding: 70px 16px 20px 16px;
    ul {
      padding-top: 10px;
      margin-left: 20px;
      border: 0 none;
      li {
        font-family: "Afacad Flux", sans-serif;
        display: inline-block;
        color: $white;
        font-weight: 500;
        font-size: 23px;
        line-height: 44px;
        border: 0 none;
        cursor: pointer;
        margin-left: 20px;
        &:hover {
          color: $green;
        }
        a {
          color: $white;
        }
      }
    }
    .logo {
      width: 340px;
      img {
        width: 340px;
        height: auto;
      }
    }
    transition: all 0.5s;
  }
  transition: all 0.5s;
  &.sticky {
    .wrapper {
      padding: 20px 0;
      transition: all 0.5s;
    }
    background: $blue;
    box-shadow: 1px 1px 14px 10px rgba(0, 31, 59, 0.75);
    transition: all 0.5s;
  }

  @include media-breakpoint-down(lg) {
    .wrapper {
      padding: 20px 20px 18px 20px;
      .logo {
        img {
          height: 50px;
          width: auto;
        }
      }
      ul {
        padding-top: 6px;
        li {
          font-size: 18px;
          &.menu-bars {
            cursor: pointer;
            position: relative;
            top: -10px;
            right: -50px;
            z-index: 100;
          }
        }
      }
    }
    &.sticky {
      .wrapper {
        padding: 12px 20px;
        transition: all 0.5s;
        height: 65px;
        ul {
          padding: 0;
        }
        .logo {
          img {
            height: 40px;
            width: auto;
          }
        }
        .menu-bars {
          position: relative;
          top: -16px;
          right: -50px;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .close-menu {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .product-menu {
      overflow: hidden;
      height: 100vh;
      top: 0;
      left: 0;
      position: absolute;
      padding-top: 140px;
      z-index: 100;
      width: 100%;
      background: linear-gradient(180deg, #055598 0%, #158be0 100%);
      ul {
        padding: 20px;
        li {
          color: $white;
          border-color: $white;
          font-family: "Afacad Flux", sans-serif;
          font-size: 30px;
          line-height: 80px;
          font-size: 49px;
          border-top: 1px solid $lightblue;
          padding: 20px 0;
          cursor: pointer;
          &:last-child {
            border-bottom: 1px solid $lightblue;
          }
          a {
            color: $white;
          }
        }
      }
     
    }
    .wrapper {
      padding: 20px;
      .logo {
        img {
          height: 40px;
          width: auto;
        }
      }
    }
    &.sticky {
      .wrapper {
        padding: 12px 20px;
        transition: all 0.5s;
        height: 50px;
        .logo {
          img {
            height: 30px;
            width: auto;
          }
        }
        .menu-bars {
          position: relative;
          top: -16px;
          right: -50px;
        }
      }
    }
  }

  // @include media-breakpoint-down(xl) {
  //   border-top: 10px solid red;
  // }
  // @include media-breakpoint-down(lg) {
  //   border-top: 10px solid orange;
  // }
  // @include media-breakpoint-down(md) {
  //   border-top: 10px solid yellow;
  // }
  // @include media-breakpoint-down(sm) {
  //   border-top: 10px solid green;
  // }
  // @include media-breakpoint-down(xs) {
  //   border-top: 10px solid blue;
  // }
}

/******************************
/
/     Banner Module
/
******************************/

.banner {
  scroll-margin: 60px;
  scroll-snap-type: y mandatory;
  width: 100%;
  height: 920px;
  background-color: $blue;
  color: $white;
  font-size: 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  .wrapper {
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    padding: 30vh 150px 0 150px;
  }
  @include media-breakpoint-down(lg) {
    .wrapper {
      padding: 40vh 32px 18px 32px;
    }
    height: 100vh;
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      h2,
      h1 {
        font-size: 39px;
        line-height: 40px;
      }
      padding: 40vh 60px 18px 20px;
    }
  }
}

/********************************
*
*   Animation Text
*
********************************/

.animation-text {
  width: 90%;
  position: relative;
  padding-bottom: 10px;
  h2 {
    font-size: 70px;
    line-height: 52px;
    color: $green;
    max-width: 90%;
    font-weight: 600;
  }
  .word {
    width: 90%;
    color: $green;
    .container {
      display: inline-block;
      position: relative;
      padding: 20px 4px;
      transition: all 200ms cubic-bezier(0.2, 1, 0.2, 1);

      &:after {
        background-color: $lightblue;
        content: "";
        position: absolute;
        top: 10px;
        right: 100%;
        bottom: 2px;
        left: 0;
        transition: all cubic-bezier(0.2, 1, 0.2, 1) 500ms 250ms;
      }
    }
    &.word-out {
      .container:after {
        right: 0;
        transition: all cubic-bezier(0.5, -0.5, 0.25, 1.5) 500ms;
      }
    }
  }
  .text-width-wrapper {
    display: inline-block;
  }

  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .hidden-text {
    position: absolute;
    top: -999px;
    left: -999px;
  }

  @include media-breakpoint-down(lg) {
    width: 90%;
  }
  @include media-breakpoint-down(sm) {
    h2 {
      font-size: 30px;
    }
  }
}

/******************************
/
/     Heading Module
/
******************************/

.heading {
  scroll-margin: 60px;
  scroll-snap-type: y mandatory;
  width: 100%;
  padding: 100px 16px;
  color: $darkblue;
  .wrapper {
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 20% 0 0;
    @include media-breakpoint-down(lg) {
      padding: 40px 60px 0px 60px;
    }
  }
  &.blue {
    background: $darkblue;
    color: $white;
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      padding: 40px 20px 0 20px;
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

/******************************
/
/     Three Column Feature Module
/
******************************/

.two-columns {
  scroll-margin: 60px;
  scroll-snap-type: y mandatory;
  width: 100%;

  &.blue {
    background: $darkblue;
    color: $white !important;
  }

  .wrapper {
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    .row {
      padding: 0 16px;
      padding-top: 80px;
      padding-bottom: 80px;

      h3 {
        font-size: 30px;
        padding: 0 20% 0 0;
        line-height: 39px;
      }
      p, li {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .wrapper {
      padding: 20px 60px;
    }
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      padding: 20px 20px;
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

/******************************
/
/     Icon Two Columns Module
/
******************************/

.icon-two-columns {
  scroll-margin: 60px;
  scroll-snap-type: y mandatory;
  width: 100%;
  .wrapper {
    padding: 0;
    max-width: 1280px;
    padding: 30px 0;
    .row {
      h3 {
        font-size: 30px;
        line-height: 39px;
        padding: 0 20% 0 0;
      }
      p {
        font-size: 20px;
        line-height: 24px;
        font-weight: 300;
      }
      img {
        max-width: 70%;
        position: relative;
        left: -60px;
      }
    }
    position: relative;
    margin: 0 auto;
    
  }
  &.blue {
    background: $darkblue;
    color: $white;
  }
  @include media-breakpoint-down(lg) {
    .wrapper {
      padding: 20px 60px;
      .row {
        img {
          max-width: 50%;
          position: relative;
          left: 0px;
          margin-bottom: 60px;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      padding: 20px 20px;
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

/******************************
/
/     Text Image Module
/
******************************/

.text-image {
  scroll-margin: 60px;
  scroll-snap-type: y mandatory;
  width: 100%;
  .wrapper {
    position: relative;
    max-width: 1480px;
    margin: 0 auto;
    padding: 0 170px;
    .row {
      border-top: 1px solid $midblue;
      padding: 40px 0;
      .col-md-6 {
        display: flex;
        align-items: center;
        justify-content: left;
        .content {
          h3 {
            margin-bottom: 20px;
            font-weight: 300;
          }
          p {
            color: $black;
            margin-bottom: 30px;
          }
          li {
            color: $black;
            padding-left: 30px;
            background: url("../images/tick-green.svg") left center no-repeat;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .wrapper {
      padding: 20px 60px;
    }
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      padding: 20px 20px;
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

/******************************
/
/     Image Text Module
/
******************************/

.image-text {
  scroll-margin: 60px;
  scroll-snap-type: y mandatory;
  width: 100%;
  .wrapper {
    position: relative;
    max-width: 1480px;
    margin: 0 auto;
    padding: 0 170px;
    .row {
      border-top: 1px solid $midblue;
      padding: 40px 0;
      .col-md-6 {
        display: flex;
        align-items: center;
        justify-content: left;
        .content {
          h3 {
            margin-bottom: 20px;
            font-weight: 300;
          }
          p {
            color: $black;
            margin-bottom: 30px;
          }
          li {
            color: $black;
            padding-left: 30px;
            background: url("../images/tick-green.svg") left center no-repeat;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .wrapper {
      padding: 20px 60px;
    }
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      padding: 20px 20px;
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

/******************************
/
/     Contact Module
/
******************************/

.contact-form {
  scroll-margin: 60px;
  scroll-snap-type: y mandatory;
  width: 100%;
  border-top: 100px solid $darkblue;
  .wrapper {
    color: $white;
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    padding: 120px 16px;
    h3 {
      margin-bottom: 30px;
      color:$darkblue;
    }
    p {
      color: $black;
      font-size: 20px;
      line-height: 30px;
      a {
        color: $lightblue;
        text-decoration: underline;
      }
      strong {
        color: $black;
        font-size: 20px;
        line-height: 34px;
        font-weight: 500;
        display: inline-block;
        padding-bottom: 20px;
      }
    }
    .form-area {
      width: 100%;
      display: block;
      form {
        width: 100%;
        padding-top: 80px;
        display: block;
        p {
          font-family: "MD Sans", sans-serif;
          font-weight: 400;
          color: $blue;
          margin-bottom: 0px;
        }
        label {
          width: 100%;
        }
        input,
        textarea {
          border: 1px solid #bdbdbd;
          box-sizing: border-box;
          border-radius: 3px;
          margin: 0 0 0 0;
          padding: 8px 20px;
          width: 100%;
          display: block;
          &[type="submit"] {
            font-family: "MD Sans", sans-serif;
            background: $midblue;
            border-radius: 25px;
            height: 50px;
            border-color: $midblue;
            color: $white;
            width: auto;
            padding: 0 40px;
            margin-top: 10px;
          }
        }
        @include media-breakpoint-down(lg) {
          padding: 40px 0px 0px 0px;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      padding: 40px 60px;
    }
  }
  .blue {
    width: 100%;
    height: 16px;
    background-color: $blue;
    display: block;
    position: relative;
    .green {
      width: 10%;
      height: 16px;
      background-color: $green;
      position: absolute;
      right: 6%;
    }
    .darkblue {
      width: 6%;
      height: 16px;
      background-color: $darkblue;
      position: absolute;
      right: 0;
    }
  }
  @include media-breakpoint-down(md) {
    .wrapper {
      padding: 60px 60px;
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      padding: 60px 20px;
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
